.react-calendar {
  background: transparent;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 0em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* ---------- The date element */
.react-calendar button {
  margin: 10;
  border: 0;
  font-weight: 400;
  font-size: 12px;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  padding-top: 3px;
  padding-left: 8px;
  height: 44px;
  background: transparent;
  font-size: 12px;
  color: #2F4660;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: transparent;
  color: #818D9B;
  font-size: 12px;
  font-family: Usual;
  font-weight: 600;
}

.react-calendar__navigation button.react-calendar__navigation__label {
  color: #2F4660;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent;
}

/* Week day classname */

.react-calendar__tile.react-calendar__month-view__days__day:disabled {
  color: #66727F;
  font-weight: 400;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  color: #2F4660;
  font-weight: 600;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font-size: 0.75em;
  margin: 1em 0 0.5em 0.5em;
  color: #66727F;
  font-family: Usual;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

abbr {
  text-decoration: none;
  font-family: Usual;
}

/* Style for weekends -- uncomment if weekends need to be styled differently */
/* .react-calendar__month-view__days__day--weekend {
  color: #afb6be;
} */
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__tile {
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 1em;
  background: transparent;
  font-size: 12px;
}

.react-calendar__tile:enabled:focus {
  background-color: #355074;
  color: #EBEFF2;
  outline: none;
  font-weight: 600;
}

.react-calendar__tile:enabled:focus-visible {
  outline: none;
}

.react-calendar__tile--now {
  color: #6ad3d1;
}

.react-calendar__tile--now:enabled:focus {
  background: #355074;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active.react-calendar__month-view__days__day {
  background: #355074;
  color: #EBEFF2;
  font-weight: 600;
}

.react-calendar__tile--active:enabled:focus {
  background: #355074;
}

.react-calendar__tile--active:enabled:focus-visible {
  outline: none;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}